import { ImprintPageBS } from "@style-variables";
import { escapeReact } from "@utils/react";
import { joinNonEmptyStrings } from "@utils/strings";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.Imprint;

  const escapeReactRoute = str => escapeReact(str, pathfinder, true, true);

  return {
    lead: {
      title: _i18n.lead.TITLE,
      text: escapeReactRoute(_i18n.lead.TEXT),
      titleAs: "h1",
      className: joinNonEmptyStrings(ImprintPageBS, "lead")
    },
    address: {
      title: _i18n.address.TITLE,
      address1: _i18n.address.ADDRESS_1,
      address2: _i18n.address.ADDRESS_2,
      postalCode: _i18n.address.POSTAL_CODE,
      city: _i18n.address.CITY,
      country: _i18n.address.COUNTRY,
      titleAs: "h2",
      className: joinNonEmptyStrings(ImprintPageBS, "address")
    },
    representative: {
      title: _i18n.representative.TITLE,
      text: _i18n.representative.TEXT,
      titleAs: "h2",
      className: joinNonEmptyStrings(ImprintPageBS, "representative")
    },
    contact: {
      title: _i18n.contact.TITLE,
      email: escapeReact(
        `${_i18n.contact.email.TITLE} <a href="mailto:${_i18n.contact.email.TEXT}">${_i18n.contact.email.TEXT}</a>`
      ),
      phone: escapeReact(
        `${_i18n.contact.phone.TITLE} <a href="tel:${_i18n.contact.phone.TEXT}">${_i18n.contact.phone.TEXT}</a>`
      ),
      titleAs: "h2",
      className: joinNonEmptyStrings(ImprintPageBS, "contact")
    },
    items: _i18n.paragraphs.map(item => ({
      title: item.TITLE,
      text: escapeReactRoute(item.TEXT),
      titleAs: "h2"
    }))
  };
};
